import { useState, useEffect } from 'react';
import { Form } from '@carecru/component-library';
import { useNavigate } from 'react-router-dom';
import {
  extractAction,
  extractCSRFToken,
  extractFields,
  extractMessages,
  getTraitsUiNode,
} from 'helpers/flow';
import {
  SettingsFlow,
  SettingsPasswordFormDataType,
  SettingsProfileFormDataType,
  UiNodeInputAttributes,
  UiText,
  UiNode,
} from 'interfaces/kratos';
import { isEmpty } from 'lodash';
import serialize from 'form-serialize';
import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import Messages from 'components/Messages';
import { SettingsHeaders, SettingsFields, SettingsButtons } from './components';
import { generateSettingsRequest, generateSettingsFlow } from './helpers';

interface FormDataType {
  [key: string]: string | string[] | FormDataType;
}

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const [flow, setFlow] = useState<SettingsFlow>({} as SettingsFlow);
  const [loading, setLoading] = useState<boolean>(false);

  const action = extractAction(flow);
  const fields = extractFields(flow);
  const csrfToken = extractCSRFToken(flow);
  const [messages, setMessages] = useState<UiText[]>(extractMessages(flow));

  const firstName = getTraitsUiNode(fields, 'firstName');
  const lastName = getTraitsUiNode(fields, 'lastName');
  const [isSignup, setIsSignup] = useState(false);

  useEffect(() => {
    setIsSignup(!(isEmpty(firstName) || isEmpty(lastName)));
  }, [firstName, lastName]);

  const onSuccessfulSettingsFlowGeneration = (response: AxiosResponse): void => {
    setFlow(response.data);
    setLoading(false);
  };

  const onFailedSettingsFlowGeneration = (): void => {
    navigate('/auth/login');
  };

  const requestSettingsFlow = (): void => {
    setLoading(true);
    generateSettingsFlow()
      .then(onSuccessfulSettingsFlowGeneration)
      .catch(onFailedSettingsFlowGeneration);
  };

  const onSuccessfulSettingsRequest = (): void => {
    setLoading(false);
    navigate('/auth/reset/success');
  };

  const getErrorMessageFromAxiosError = (error: AxiosError) => {
    const validations = error.response?.data?.ui?.nodes || [];
    const notPassingValidations = validations.filter((validation: UiNode) =>
      validation.messages.some((message) => message.type === 'error')
    );

    const errorMessage =
      notPassingValidations.length > 0
        ? notPassingValidations[0].messages[0].text
        : error.toString();

    return errorMessage;
  };

  const onFailedSettingsRequest = (error: AxiosError | string): void => {
    // @ts-ignore
    if (error.response?.data.state === 'success') {
      return onSuccessfulSettingsRequest();
    }

    const errorMessage = axios.isAxiosError(error)
      ? getErrorMessageFromAxiosError(error)
      : error.toString();

    setMessages([
      {
        id: 0,
        text: errorMessage,
        type: 'error',
      },
    ]);

    return setLoading(false);
  };

  const getSettingRequest = (
    formAction: string,
    formData: FormDataType
  ): AxiosPromise | Promise<any> => {
    const csrfTokenValue = (csrfToken.attributes as UiNodeInputAttributes).value;
    const payload = {
      csrf_token: csrfTokenValue,
      method: 'password',
      password: formData.password,
    } as SettingsPasswordFormDataType;
    const updatePassword = generateSettingsRequest(formAction, payload);

    if (!formData['traits.firstName']) return updatePassword();

    const payloadProfile = {
      csrf_token: csrfTokenValue,
      method: 'profile',
      traits: {
        ...flow.identity.traits,
        firstName: formData['traits.firstName'],
        lastName: formData['traits.lastName'],
      },
    } as SettingsProfileFormDataType;
    const updateProfile = generateSettingsRequest(formAction, payloadProfile);
    return Promise.all([updatePassword(), updateProfile()]);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData: FormDataType = serialize(form, { hash: true, empty: true });
    const settingsRequest: AxiosPromise | Promise<any> = getSettingRequest(form.action, formData);
    settingsRequest.then(onSuccessfulSettingsRequest).catch(onFailedSettingsRequest);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(requestSettingsFlow, []);

  return (
    <Form action={action} onSubmit={onSubmit} className="settings-form__container">
      <SettingsHeaders flow={flow} isSignup={isSignup} />
      <Messages messages={messages} loading={loading} />
      <SettingsFields fields={fields} />
      <SettingsButtons loading={loading} isSignup={isSignup} />
    </Form>
  );
};

export default Settings;
